import React from "react";
import Layout from "../layouts/index";
import CourseImage from "../images/featuredImages/demoImage.png";
import Link from "gatsby-link";

class CourseDetails extends React.Component {
  render() {
    return (
      <Layout>
        {/* Course info */}
        <div className="container-fluid bg-white">
          <div className="container margin-from-header pt-6 pb-6">
            <div className="row d-flex justify-content-md-center reverse-flex-mobile">
              <div className="col-sm-12 col-md-5">
                <h1>Vietnamese Numbers</h1>
                <p>
                  Learn everything about Vietnamese numbers from basic counting
                  with audio to money and simple calculations.
                </p>
                <div className="row pl-1 mt-3">
                  <Link
                    className="btn btn-primary"
                    to="/vietnamese-numbers-money"
                  >
                    Start learning
                  </Link>
                  <h1 className="text-success mb-0 ml-md-2">FREE</h1>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <img
                  src={CourseImage}
                  className="img-fluid float-right rounded"
                  alt="Easy Vietnamese conversation"
                ></img>
              </div>
            </div>
          </div>
        </div>

        {/* Course contents */}
        <div className="container margin-from-header pb-6">
          <div className="row mb-6 justify-content-md-center">
            <div className="col-sm-12 col-md-8">
              <h2>What will you achieve?</h2>
              <ul>
                <li>Know how to count small numbers from 0 to 100</li>
                <li>
                  Learn thousands, millions and billions to talk about
                  Vietnamese money
                </li>
                <li>
                  Know how to say, ask about prices and bargain in Vietnamese
                </li>
                <li>Learn how to do basic math calculations</li>
              </ul>

              <h2 className="pt-6">Table of Contents</h2>
              <ol className="list-group">
                <li className="list-group-item">Lesson 1: Numbers 0-10</li>
                <li className="list-group-item">
                  Lesson 2: Numbers Rule "0" - 10, 20, 30, ...
                </li>
                <li className="list-group-item">
                  Lesson 3: Numbers Rule "1" - 11, 21, 31, ...
                </li>
                <li className="list-group-item">
                  Lesson 4: Numbers Rule "4" - 14, 21, 31, ...
                </li>
                <li className="list-group-item">
                  Lesson 5: Numbers Rule "5" - 15, 25, 35, ...
                </li>
                <li className="list-group-item">
                  Lesson 5: Review Numbers 0 - 99{" "}
                </li>
                <li className="list-group-item">
                  Lesson 5: Hundred in Vietnamese 100, 200, 300, ...
                </li>
              </ol>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CourseDetails;
